// variables mathias
$dark-main: #5c1a25;
$main-color: #8b2939;
$second-color: #ffc400;

// variables dome
// colors
$white : #ffffff;
$lightblue : #49CBD3;
$black : #000000;
$darkgrey : #909090;
$blue : #00ABC8;
$text-dark : #595B60;

// fonts
$font-bold : BreuerText-Bold, Helvetica, Arial, sans-serif;
$font-regular : BreuerText-Regular, Helvetica, Arial, sans-serif;
$font-medium : BreuerText-Medium, Helvetica, Arial, sans-serif;
$font-light : BreuerText-Light, Helvetica, Arial, sans-serif;

// letter spacing

$av_180 : 0.18em;
$av_160 : 0.16em;
$av_80 : 0.08em;
$av_20 : 0.02em;

// other

$header-height : 49px;
$default-transition-time : 200ms;
$text-color-hover : #49CBD3;
$footer-bottom-height : 45px;

// modules
@import "modules/font";
@import "modules/formulars";
@import "modules/header";
@import "modules/ueberuns.scss";


// MEDIA QUERY DESKTOP LARGE
// from 1920 to max
// MEDIA QUERY DESKTOP LARGE
@media screen and (min-width: 1920px) {
    body {
        main {
            width: 70%;
            > .plus20-container {
                img {
                    position: absolute;
                    z-index: 1000;
                    width: 444px;
                    left: 200px;
                }
            }
            > .keyvisual {
                .keyvisual-homepage {
                    width: 100vw !important;
                    &:before {
                        width: 100vw !important;
                    }
                    &:after {
                        width: 100vw !important;
                    }
                }
                .keyvisual-dimmer {
                    width: 100vw !important;
                }
            }
            .desktop-large-bg-grey_dark {
                background: #5D5D5D;
            }
        }
    }
}

// MEDIA QUERY DESKTOP
// from 1919 to 1367
// MEDIA QUERY DESKTOP
body {
    margin: 0;
    padding: 0;
    background: $black;
    overflow-x: hidden;
    #alert_container {
        padding-top: 40px;
        padding-bottom: 20px;
        > div {
            > h2 {
                color: #49CBD3;
                text-transform: none;
                letter-spacing: $av_20 !important;
                line-height: 71px !important;
                font-size: 60px;
            }
            > p {
                color: #49CBD3;
                font-size: 39px;
                letter-spacing: $av_20 !important;
                line-height: 71px !important;
            }

        }
    }
    #ueber-uns {
        display: block;
        margin-top: 20px;
        > .background_container {
            background: #5D5D5D;
            padding: 0 35px;
            width: 534px;
            height: auto;
            transform: translateX(-25px);
            font-size: 0;
            > div {
                width: 50%;
                display: inline-block;
                vertical-align: top;
                padding-bottom: 20px;
                > .img-container > img {
                    height: 210px;
                    padding-bottom: 10px;
                }
                h3 {
                    font-size: 25px;
                    font-family: $font-bold;
                    font-weight: 700;
                    line-height: 24px;
                    margin: 0;
                    padding: 0;
                }
                p {
                    margin: 0;
                    padding: 5px 0 0;
                    font-size: 19px;
                    line-height: 27px;
                    max-width: 210px;
                    font-family: $font-regular;
                }
                a {
                    font-size: 19px;
                    line-height: 24px;
                    text-decoration: none;
                    font-family: $font-regular;
                }
                &:nth-child(2) {
                    > .img-container {
                        position: relative;
                        > img {
                            position: absolute;
                            right: 0;
                        }
                    }
                    h3 {
                        margin-top: 220px;
                        width: 210px;
                        margin-left: 57px;
                        text-transform: uppercase;
                    }
                    p {
                        width: 210px;
                        margin-left: 57px;
                    }
                    a {
                        width: 210px;
                        margin-left: 57px;
                    }
                }
            }
        }
    }
    #ueber-uns-mobile {
        display: none;
        > div > a {
            padding-bottom: 20px !important;
        }
    }
    .capit {
        text-transform: uppercase;
    }
    #cookie-reminder {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 3000;
        height: 150px;
        width: 100%;
        background: $lightblue;
        font-family: $font-regular;
        font-size: 17px;
        > p {
            padding: 0 20px;
        }
        > button {
            width: 200px;
            > a {
                color: $lightblue;
                text-decoration: none;
            }
            &:hover {
                color: black;
                background: $blue;
                > a {
                    color: black;
                }
            }
        }
        > span {
            width: 20px;
            height: 20px;
            position: fixed;
            border-radius: 50%;
            right: 8px;
            bottom: 120px;
            &:before {
                display: block;
                content: "";
                position: relative;
                top: 9px;
                left: 0;
                background: white;
                width: 20px;
                height: 2px;
                transform: rotateZ(45deg);
            }
            &:after {
                display: block;
                content: "";
                position: relative;
                top: 7px;
                left: 0;
                background: white;
                width: 20px;
                height: 2px;
                transform: rotateZ(-45deg);
            }
            &:hover {
                background: rgba(255, 255, 255, 0.4);
            }
        }
    }
    > main {
        overflow-x: hidden;
        #map {
            width: 100%;
            height: 515px;
        }
        .brand-container {
            display: block;
            max-width: 890px;
            width: 100%;
            margin-left: 30px;
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1000;
            #mafides-brand {
                display: none;
                position: relative;
                top: 0;
                left: 0;
                width: 261px;
                margin-right: 629px;
            }
        }
        .form-confirmation {
            font-size: 12px;
            line-height: 25px;
            padding: 0;
            margin: 0;
            color: white;
            > * {
                display: inline-block;
                font-size: 12px;
                line-height: 15px;
                padding: 5px 0px 15px 0px;
                margin: 0;
                > input {
                    margin: 0;
                    padding: 0;
                    line-height: 25px;
                    height: 20px;
                    transform: translateY(5px);
                    margin-right: 10px;
                }
                &.missing {
                    color: red;
                }
            }
        }
        .plus20-container {
            position: relative;
            top: 0;
            left: 0;
            width: 890px;
            margin: 0 auto;
            padding: 0;
            > img {
                position: absolute;
                z-index: 1000;
                top: 414px;
                left: 410px;
            }
        }
        > .keyvisual {
            position: relative;
            top: 49px;
            margin-bottom: 49px;
            > .keyvisual-homepage {
                // you cant use $header-height in calculation... | 95px bottom
                height: 777px;
                width: 100%;
                top: $header-height;
                // //background-image: url('../img/IMG_1125 Home.jpg');
                // background-repeat: no-repeat;
                // background-size: cover;
                // background-position: 50%;

                &.contain {
                    &:after {
                        background-size: contain;
                    }
                }
                &.cover {
                    &:after {
                        background-size: cover;
                    }
                }
                &:before {
                    display: block;
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: white;
                    z-index: 10;
                }
                &:after {
                    display: block;
                    content: "";
                    top: 0;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    z-index: 20;
                }
            }
            > .keyvisual-dimmer {
                position: absolute;
                z-index: 20;
                bottom: 0;
                width: 100%;
                // $header-height
                height: 60%;
                background: linear-gradient(180deg, transparent, black);
            }
            > .keyvisual-content {
                display: block;
                position: absolute;
                z-index: 30;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                margin-bottom: 10px;
                color: $white;
                > h1 {
                    padding: 0;
                    margin: 0;
                    font-family: $font-light;
                    font-weight: lighter;
                    font-size: 86px;
                    line-height: 75px;
                    letter-spacing: $av_160;
                    padding-bottom: 10px;
                    text-transform: uppercase;
                }
                > h2 {
                    margin: 0;
                    padding: 0;
                    font-family: $font-bold;
                    font-weight: bold;
                    font-size: 55px;
                    line-height: 54px;
                    letter-spacing: $av_160;
                    text-transform: uppercase;
                    // padding-bottom: 25px;
                    padding-bottom: 15px;
                }
                > p {
                    margin: 0;
                    padding: 0;
                    font-family: $font-regular;
                    font-weight: normal;
                    line-height: 31px;
                    font-size: 10px;
                    font-size: 19px;
                    margin-top: 20px;
                }
            }
        }
        .keyvisual-subtext {
            display: block;
            font-family: $font-medium;
            font-weight: normal;
            font-size: 19px;
            line-height: 31px;
        }
        .keyvisual-subtext-mobile {
            display: none;
            font-family: $font-medium;
            font-weight: normal;
            font-size: 19px;
            line-height: 31px;
        }
        .mobile-gradient {
            display: none;
        }
        > .tile-menu {
            margin-bottom: 80px;
            > .tile-menu-wrapper {
                margin: 0 auto;
                margin-top: 49px;
                //width: calc(100% - 30px);
                width: 1100px;
                height: auto;
                font-size: 0;
                > .tile-line {
                    > .tile {
                        display: inline-block;
                        height: 208px;
                        padding: 0;
                        margin: 0;
                        position: relative;
                        font-size: 16px;
                        cursor: pointer;
                        &.size-short {
                            width: 208px;
                            margin: 0 7.5px;
                        }
                        &.size-long {
                            width: 431px;
                            margin: 0 7.5px;
                        }
                        > .tile-details {
                            position: absolute;
                            bottom: 13px;
                            left: 0;
                            > img {
                                position: absolute;
                                left: 30px;
                                bottom: 105px;
                                width: 54px;
                                height: 54px;
                            }
                            > h3 {
                                padding: 0;
                                margin: 0;
                                font-family: $font-light;
                                font-weight: lighter;
                                font-size: 36px;
                                line-height: 34px;
                                margin-left: 20px;
                                letter-spacing: 0.05em;
                                transition: color $default-transition-time;
                                &.text-size-big {

                                }
                                &.text-size-small {
                                    font-size: 26px;
                                    line-height: 30px;
                                    letter-spacing: 0.01em;
                                }
                                &.text-size-normal {
                                    
                                }
                            }
                            > p {
                                font-family: $font-regular;
                                font-weight: normal;
                                font-size: 18px;
                                line-height: 26px;
                                margin: 0;
                                margin-left: 20px;
                                margin-top: 3px;
                                padding: 0;
                                transition: color $default-transition-time;
                            }
                        }
                        &#tile_buchhaltung {
                            margin-left: 0;
                            // set background paths
                            > .tile-hover-bg-wrapper {
                                background-image: url('../../images/buchhaltung-kachel-homepage-mafides.jpg');
                            }
                        }
                        &#tile_immobilienverwaltung {
                            > .tile-hover-bg-wrapper {
                                background-image: url('../../images/immobilienverwaltung-kachel-homepage-mafides.jpg');
                            }
                        }
                        &#tile_programm {
                            margin-right: 0;
                            > .tile-hover-bg-wrapper {
                                background-image: url('../../images/programm-20-plus-kachel-homepage-mafides.jpg');
                            }
                        }
                        &#tile_personal_administration {
                            margin-left: 0;
                            > .tile-hover-bg-wrapper {
                                background-image: url('../../images/personaladministration-kachel-mafides.jpg');
                            }
                        }
                        &#tile_beratung {
                            > .tile-hover-bg-wrapper {
                                background-image: url('../../images/beratung-kachel-mafides.jpg');
                            }
                        }
                        &#tile_steuern {
                            > .tile-hover-bg-wrapper {
                                background-image: url('../../images/steuern-kachel-homepage-mafides.jpg');
                            }
                        }
                        &#tile_ueber_uns {
                            margin-right: 0;
                            > .tile-hover-bg-wrapper {
                                background-image: url('../../images/ueber-uns-kachel-homepage-mafides.jpg');
                            }
                        }
                        &:hover {
                            > .tile-hover-bg-wrapper {
                                opacity: 1;
                                transition: opacity $default-transition-time;
                            }
                            > .tile-details {
                                > h3 {
                                    color: $text-color-hover;
                                    transition: color $default-transition-time;
                                }
                                > p {
                                    color: $text-color-hover;
                                    transition: color $default-transition-time;
                                }
                            }
                        }
                        &.bg-grey, &.bg-grey_dark {
                            color: $white;
                        }
                        &.bg-blue {
                            color: $text-dark;
                        }
                    }
                    &:first-child {
                        margin-bottom: 14px;
                    }
                }
            }
        }
        .quote-container {
            font-family: $font-regular;
            font-weight: normal;
            font-size: 26px;
            line-height: 35px;
            padding: 40px 0;
            > * > blockquote {
                margin: 0;
                padding: 0;
                letter-spacing: 0.08em;
                > .quote-author {
                    font-size: 18px;
                }
            }
        }
    }
    footer {
        font-family: $font-light;
        font-weight: lighter;
        font-size: 20px;
        line-height: 24px;
        margin: 0;
        padding: 0;
        margin-top: 85px;
        .footer-bottom-line {
            display: block;
            p {
                letter-spacing: 0.032em;
            }
        }
        .footer-bottom-line-mobile {
            display: none;
        }
        > .footer-row-container {
            padding-left: 80px;
            margin-left: -80px;
            min-height: 397px;
            overflow-x: hidden;
            > div {
                max-width: 1030px;
                margin: 0 auto;
                transform: translateX(120px);
                overflow-x: hidden;
            }
            > * > .footer-icons {
                float: left;
                margin-top: 30px;
                margin-bottom: 20px;
                height: 50px;
                width: 100%;
                font-size: 0;
                > .f-icon-wrapper {
                    width: 33.33%;
                    height: 100%;
                    display: inline-block;
                    > .f-icon {
                        height: 50px;
                        width: auto;
                    }
                }
            }
            > * > .f-h3s {
                font-size: 0;
                > .f-h3-wrapper {
                    font-size: 30px;
                    width: 33.33%;
                    height: 30px;
                    display: inline-block;
                    padding: 0;
                    margin: 0;
                    padding-top: 10px;
                    transform: translateX(-74px);
                    > h3 {
                        padding: 0;
                        margin: 0;
                        font-size: 30px;
                        letter-spacing: 0.16em;
                    }
                    &:last-child {
                        transform: translateX(0);
                    }
                }
            }
            > * > .f-lists {
                font-size: 0;
                > .f-list-wrapper {
                    vertical-align: top;
                    width: 33.33%;
                    height: 100%;
                    display: inline-block;
                    &:last-child {
                        > ul {
                            margin-left: 0;
                        }
                    }
                    > ul {
                        padding: 0;
                        margin: 0;
                        //margin-left: 70px;
                        list-style-type: none;
                        > li {
                            line-height: 31px;
                            > a {
                                text-decoration: none;
                                color: #ffffff;
                                font-size: 20px;
                                line-height: 31px;
                                &:hover {
                                    color: $darkgrey;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
            > .footer-pre-bottom {
                > p {
                    display: inline-block;
                    margin-right: 222px;
                    font-family: $font-bold;
                    font-weight: bold;
                    &:last-child {
                        margin-left: 15px;
                        margin-right: 0;
                        font-family: $font-light;
                        font-weight: lighter;
                    }
                    > a {
                        color: $white;
                        text-decoration: none;
                        outline: none;
                        &:hover {
                            color: $darkgrey;
                        }
                    }
                }
            }
        }
        > .footer-bottom {
            height: $footer-bottom-height;
            width: 100%;
            color: $lightblue;
            > * {
                height: 1$footer-bottom-height;
                > p {
                    padding: 0;
                    margin: 0;
                    color: #49CBD3;
                    vertical-align: middle;
                    line-height: $footer-bottom-height;
                }
            }
        }
    }
}
.text-block {
    font-family: BreuerText-Light, Arial, Helvetica, sans-serif;
    font-size: 30px;

}

.t-author {
    font-size: 18px;
    line-height: 35px;
}

.text-center {
    text-align: center;
}

.container {
    width: 100%;
    margin: 0;
    padding: 0;
}

button {
    border: none;
    background: $black;
    color: $lightblue;
    font-family: $font-regular;
    font-weight: normal;
    font-size: 22px;
    height: 46px;
    width: calc(100% - 28px);
    margin: 14px;
    outline: none;
    transition: background $default-transition-time, color $default-transition-time;
    &:hover {
        cursor: pointer;
        color: $black;
        background: $lightblue;
        transition: background $default-transition-time, color $default-transition-time;
    }
    &.bg-grey, &.bg-grey_dark {
        color: $white;
        &:hover {
            color: $darkgrey;
            background: $white;
        }
    }
    &.stand-alone {
        width: 245px;
        padding: 0;
        margin: 0;
        margin-right: 46px;
    }
}

.align-top {
    vertical-align: top;
}

.align-bottom {
    vertical-align: bottom;
}

.test-block {
    display: block;
    background: red;
    border: 2px solid blue;
    width: 98px;
    height: 98px;
    position: relative;
    z-index: 1000000;
}
.c-white {
    color: $white;
}
.c-lightblue {
    color: $lightblue;
}
.c-dark {
    color: $text-dark;
}
.tile-hover-bg-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    transition: opacity $default-transition-time;
}

.center {
    margin: 0 auto;
}

.con-maxWidth {
    max-width: 890px;
}

.bg-grey {
    background: #909090;
}
.bg-grey_dark {
    background: #5D5D5D;
}
.bg-blue {
    background: #49CBD3;
}
.bg-black {
    background: #000000;
}

// bold 
b, strong, em {
    font-family: $font-bold;
    font-weight: bold;
}

// spacer

.spacer {
    width: 100%;
    display: block;
}

.spacer-20 {
    width: 100%;
    height: 20px;
}
.spacer-50 {
    width: 100%;
    height: 50px;
}
.spacer-65 {
    width: 100%;
    height: 65px;
}
.spacer-80 {
    width: 100%;
    height: 80px;
}
.spacer-120 {
    width: 100%;
    height: 120px;
}

.inp-size-half {
    width: 50%;
    display: inline-block;
    &.first {
        float: left;
        margin-right: 7px;
        margin-left: 0;
        width: calc(50% - 7px);
    }
    &.last {
        float: right;
        margin-left: 7px;
        margin-right: 0;
        width: calc(50% - 7px);
    }
}

.inp-size-full {
    display: block;
    width: 100%;
}

input {
    border: none;
    font-family: $font-medium;
    font-weight: normal;
    margin-bottom: 14px;
    height: 46px;
    font-size: 16px;
    line-height: 22px;
    text-indent: 16px;
    outline: none;
    padding: 0;
}

// editor styling
.text-container {
    > h2 {
        margin: 0;
        padding: 0;
        font-size: 54px;
        line-height: 55px;
        letter-spacing: $av_160;
        font-family: $font-bold;
        font-weight: bold;
        text-transform: uppercase;
    }
    > h3 {
        margin: 0;
        padding: 0;
        font-size: 19px;
        font-family: $font-bold;
        font-weight: bold;
        line-height: 31px;
        letter-spacing: $av_20;
        text-transform: uppercase;
    }
    > p {
        margin: 0;
        padding: 0;
        font-size: 18px;
        line-height: 26px;
        font-family: $font-regular;
        font-weight: normal;
        letter-spacing: $av_20;
    }
    > ul {
        list-style-type: none;
        padding: 0 0 20px 0;
        margin: 0;
        > li {
            font-family: $font-regular;
            font-weight: normal;
            font-size: 19px;
            line-height: 31px;
            letter-spacing: $av_20;
            padding-left: 20px;
            &:before {
                display: inline-block;
                content: "»";
                width: 0;
                color: $lightblue;
                position: relative;
                left: -13px;

            }
        }
    }
    a {
        &:hover {
            color: white;
        }
    }
}


// MEDIA QUERY TABLET
// from 769px to 1366
// MEDIA QUERY TABLET
// @media screen and (max-width: 1366px) {
//     body {
//         > header {
//             .header-iconContainer {
//                 display: none !important;
//             }
//         }
//         > main {
//             .text-container {
//                 max-width: 980px;
//                 > p {
//                     padding-right: 20px;
//                 }
//             }
//             // .keyvisual-content {
//             //     max-width: 980px !important;
//             //     > h1 {
//             //         font-size: 86px !important;
//             //         overflow: hidden !important;
//             //         padding-left: 46px !important;
//             //         text-transform: uppercase;
//             //         line-height: 75px !important;
//             //         margin-bottom: 10px !important;
//             //     }
//             //     > h2 {
//             //         padding-left: 46px !important;
//             //         text-transform: uppercase;
//             //     }
//             // }
//             // h2 {
//             //     font-size: 50px !important;
//             //     line-height: 45px !important;
//             //     text-transform: uppercase;
//             // }
//             // > .tile-menu {
//             //     > .tile-menu-wrapper {
//             //         height: auto;
//             //         // width: 100%;
//             //         > .tile-line {
//             //             > .tile {
//             //                 height: 19.3vw;
//             //                 > .tile-details {
//             //                     bottom: 8px;
//             //                     > h3 {
//             //                         line-height: 35px !important;
//             //                         margin-left: 10px;
//             //                         &.text-size-big {
//             //                             font-size: 25px;
//             //                             margin-left: 10px;
//             //                         }
//             //                         &.text-size-small {
//             //                             font-size: 14px;
//             //                             margin-left: 10px;
//             //                         }
//             //                         &.text-size-normal {
//             //                             font-size: 20px;
//             //                             line-height: 25px;
//             //                         }
//             //                         -ms-hyphens: auto;
//             //                         -webkit-hyphens: auto;
//             //                         hyphens: auto;
//             //                     }
//             //                     > p {
//             //                         margin-left: 10px;
//             //                         font-size: 12px;
//             //                         line-height: 15px;
//             //                         margin-top: 0;
//             //                     }
//             //                 }
//             //                 > .mobile-overlay {
//             //                     display: none;
//             //                 }
//             //             }
//             //         }
//             //     }
//             // }
//         }
//         footer {
//             .footer-row-container {
//                 padding-left: 0 !important;
//                 width: 100% !important;

//             }
//         }
//     }
// }

@media screen and (max-width: 1366px) {
    body {
        footer {
            .footer-row-container {
                padding-left: 0 !important;
                width: 100% !important;

            }
        }
    }
}

// MEDIA QUERY MOBILE -----
// -- From MIN to 768px ---
// MEDIA QUERY MOBILE -----
@media screen and (max-width: 768px) {
    body {
        #alert_container {
            padding-top: 40px;
            padding-bottom: 20px;
            > div {
                > h2 {
                    color: #49CBD3;
                    text-transform: none;
                    letter-spacing: $av_20 !important;
                    line-height: 40px !important;
                    font-size: 30px !important;
                }
                > p {
                    color: #49CBD3;
                    font-size: 30px !important;
                    letter-spacing: $av_20 !important;
                    line-height: 40px !important;
                }
    
            }
        }
        #cookie-reminder {
            height: 300px;
            > span {
                bottom: 274px;
            }
            > button {
                width: calc(100% - 28px);
            }

        }
        #ueber-uns {
            display: none;
        }
        #ueber-uns-mobile {
            display: block;
            margin-top: 10px;
            > .container {
                position: relative;
                z-index: 50;
                .img-first, .img-second {
                    width: 100%;
                    height: 238px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: 50%;
                }
                h3 {
                    margin: 0;
                    padding: 0 20px;
                    font-size: 20px;
                    line-height: 22px;
                    font-family: $font-bold;
                    font-weight: bold;
                    position: relative;
                    z-index: 100;
                    text-transform: uppercase;
                    letter-spacing: $av_20;
                }
                p {
                    margin: 0;
                    padding: 0 20px;
                    font-size: 19px;
                    font-family: $font-regular;
                    font-weight: normal;
                    line-height: 22px;
                    position: relative;
                    z-index: 100;
                }
                a {
                    margin: 0;
                    padding: 0 20px;
                    font-size: 19px;
                    font-family: $font-regular;
                    font-weight: normal;
                    line-height: 22px;
                    margin-bottom: 20px;
                    position: relative;
                    z-index: 100;
                }
                .zitat {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    font-size: 20px;
                    letter-spacing: $av_80;
                    margin-bottom: 20px;
                    position: relative;
                    z-index: 100;
                }
                .ueber-uns-mobile-gradient {
                    position: absolute;
                    width: 100%;
                    height: 120px;
                    left: 0;
                    top: -60px;
                    background: linear-gradient(transparent, black, transparent);
    
                }
            }
            .team-img {
                width: 100%;
                height: 238px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 50%;
            }
            h3 {
                margin: 0;
                padding: 0 20px;
                font-size: 20px;
                line-height: 22px;
                font-family: $font-bold;
                font-weight: bold;
                position: relative;
                z-index: 100;
                text-transform: uppercase;
                letter-spacing: $av_20;
            }
            p {
                margin: 0;
                padding: 0 20px;
                font-size: 19px;
                font-family: $font-regular;
                font-weight: normal;
                line-height: 22px;
                position: relative;
                z-index: 100;
            }
            a {
                display: block;
                margin: 0;
                padding: 0 20px;
                font-size: 19px;
                font-family: $font-regular;
                font-weight: normal;
                line-height: 22px;
                margin-bottom: 30px;
                position: relative;
                z-index: 100;
            }
            .img-container {
                position: relative;
                .ueber-uns-mobile-gradient {
                    height: 140px;
                    width: 100%;
                    position: absolute;
                    background: linear-gradient(transparent, black, transparent);
                    bottom: -70px;
                    left: 0;
                }
            }
        }
        #header-logo {
            left: 20px !important;
        }
        #plus20_svg {
            left: 20px;
            top: 69px;
            height: 50px;
            width: 50px;
        }
        .quote-container {
            // display: none;
        }
        .con-maxWidth {
            width: calc(100% - 40px);
            margin: 0 auto;
        }
        .download-icon {
            top: 15px !important;
            left: -10px !important;
        }

        > header {
            height: 52px;
            > nav {
                > .header-menu {
                    display: none;
                }
                > .header-iconContainer {
                    display: block !important;
                    > .header-icon {
                        margin-top: 5px;
                        &:first-child {
                            display: none;
                        }
                        &:last-child {
                            padding-right: 16px;
                        }
                    }
                }
                > .mobile-header-menu-icon {
                    height: 20px;
                    width: 26px;
                    position: absolute;
                    right: 65px;
                    top: 18px;
                    display: block;
                    &:hover {
                        cursor: pointer;
                    }
                    > .click-area {
                        width: 26px;
                        height: 2px;
                        background: $lightblue;
                        position: absolute;
                        top: 7px;
                        right: 0;
                        &:before {
                            display: block;
                            content: "";
                            position: absolute;
                            background: $lightblue;
                            width: 26px;
                            height: 2px;
                            top: -8px;
                            right: 0;
                        }
                        &:after {
                            display: block;
                            content: "";
                            position: absolute;
                            background: $lightblue;
                            width: 26px;
                            height: 2px;
                            top: 8px;
                            right: 0;
                        }
                    }
                }
            }
            > #header-logo {
                left: 20px;
            }
        }
        > main {
            .content-container {
                transform: translateY(-90px) !important;
                margin-bottom: -90px !important;
                > h1 {
                    padding-left: 20px;
                }
            }
            .keyvisual-subtext {
                display: none !important;
                > .text-container {
                    > p {
                        line-height: 24px;
                        font-size: 17px;
                    }
                }
            }
            .keyvisual-subtext-mobile {
                display: block;
                > .text-container {
                    > p {
                        line-height: 24px;
                        font-size: 17px;
                    }
                }
            }
            .brand-container {
                display: none;
            }
            .text-container {
                > p {
                    padding: 0;
                    font-size: 17px;
                }
                ul {
                    li {
                        font-size: 17px;
                    }
                }
            }
            h2 {
                font-size: 20px !important;
                line-height: 25px !important;
            }
            .keyvisual-content {
                > h1 {
                    padding: 0 !important;
                    padding-bottom: 10px !important;
                    text-transform: uppercase;
                }
                > h2 {
                    padding: 0 !important;
                    text-transform: uppercase;
                }
            }
            > .tile-menu {
                > .tile-menu-wrapper {
                    width: 100%;
                    > .tile-line {
                        margin: 0 !important;
                        > .tile {
                            width: 100% !important;
                            margin: 0 !important;
                            height: 48vw !important;
                            > .tile-hover-bg-wrapper {
                                opacity: 1;
                            }
                            > .tile-details {
                                bottom: 20px !important;
                                color: $lightblue !important;
                                z-index: 20;
                                user-select: none !important;
                                pointer-events: none;
                                > h3 {
                                    margin-left: 25px !important;
                                    z-index: 20;
                                }
                                > p {
                                    margin-left: 25px !important;
                                    z-index: 20;
                                }
                            }
                            > .mobile-overlay {
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background: linear-gradient(180deg, transparent, $black);
                                z-index: 10;
                                user-select: none;
                                pointer-events: none;
                            }
                            > a {
                                user-select: all;
                            }
                        }
                    }
                }
            }
            > .keyvisual {
                > .keyvisual-homepage {
                    height: 260px;

                }
                > .keyvisual-content {
                    > h1 {
                        font-size: 40px !important;
                        line-height: 40px !important;
                        letter-spacing: 7px !important;
                        margin-left: 0 !important;
                        text-transform: uppercase;
                    }
                    > h2 {
                        font-size: 20px !important;
                        line-height: 25px !important;
                        letter-spacing: 5px !important;
                        margin-left: 0 !important;
                        text-transform: uppercase;
                    }
                }
            }
            .mobile-gradient {
                display: block;
                height: 60px;
                width: 100%;
                margin-top: -60px;
                background: linear-gradient(0, black, transparent);
            }
        }
        footer {
            .footer-bottom-line {
                display: none;
            }
            .footer-bottom-line-mobile {
                display: block;
                text-align: start;
            }
            > .footer-row-container {
                min-height: 0;
                > div:first-child {
                    display: none;
                }
                > div {
                    transform: translateX(0);
                }
                > .footer-pre-bottom {
                    > p {
                        padding: 10px 0;
                        padding-right: 50px;
                        margin: 0;
                        &:last-child {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
// small mobile
@media screen and (max-width: 450px) {
    body {
        #cookie-reminder {
            button {
                margin: 5px 14px;
            }
        }
    }
}