// MEDIA QUERY DESKTOP LARGE
// from 1920 to max
// MEDIA QUERY DESKTOP LARGE
@media screen and (min-width: 1920px) { 
    body {
        main {
            .formular {
                &#form_beratung {
                    position: fixed;
                    left: 70%;
                    top: 20%;
                    z-index: 100;
                    width: 329px;
                    h2 {
                        font-size: 22px;
                        padding: 15px;
                        letter-spacing: 6px;
                        line-height: 25px;
                    }
                    > .formular-wrapper {
                        display: grid;
                        flex-direction: column;
                        transform: translateX(0);
                        &.bg-grey_dark {
                            background: transparent;
                        }
                        .img {
                            display: grid;

                            order: -1;
                            .profile-img {
                                width: 100%;
                                height: 238px !important;
                                max-width: 10000px;
                                margin: 0;
                            }
                        }
                        > .formular-content-left {
                            padding: 0;
                            background: #5D5D5D !important;
                            width: 100%;
                            > h2 {
                                width: 100%;
                            }
                            &:first-child {
                                background: transparent;
                            }
                            > form {
                                display: inline-block;
                                margin: 30px 0;
                                padding: 0 15px;
                                width: calc(100% - 30px);
                                > .inp-size-half {
                                    width: 100%;
                                    float: left;
                                    margin-left: 0;
                                }
                                > .inp-size-full {
                                    width: 100%;
                                }
                                > textarea {
                                    width: calc(100% - 32px);
                                }
                            }
                        }
                        > .formular-content-right {
                            width: 100%;
                            &.img {
                                > img {
                                    width: 100%;
                                }
                            }
                            > .profile-img {
                                &:before {
                                    opacity: 0;
                                }
                                > .profile-details {
                                    display: none;
                                }
                            }
                            > .profile-details-mobile {
                                display: block;
                                padding: 25px;
                                > h3 {
                                    font-size: 20px;
                                    text-transform: uppercase;
                                }
                                > p {
                                    font-size: 19px;
                                }

                            }
                            
                        }
                    }
                }
            }
        }
    }
}
// QUERY DESKTOP DEFAULT ---
// --- from 1367px to 1919px
// QUERY DESKTOP DEFAULT ---
body {
    main {
        .form_alignment {
            max-width: 980px;
            margin: 0 auto;
        }
        .formular {
            font-family: $font-light;
            .form-text {
                display: block;
                font-size: 19px;
                line-height: 31px;
                font-family: $font-regular;
                padding-left: 46px;
            }
            .desktop-large-bg-grey_dark {
                background: transparent;
            }
            &#form_beratung {
                max-width: 862px;
                .formular-wrapper {
                    .profile-img {
                        max-width: 211px;
                        height: 238px;
                        margin-top: 15px;
                        margin-left: 50px;
                    }
                    .profile-details {
                        max-width: 211px;
                        margin-left: 50px;
                        > h3 {
                            text-transform: uppercase;
                        }
                    }
                }
                form {
                    padding-right: 0;
                    > p {
                        font-size: 12px;
                        color: white;
                    }
                }
            }
            &#form_download {
                max-width: 640px;
                    .profile-img {
                        // new layout Kale
                        background-repeat: no-repeat;
                        height: 75px;
                        width: 100%;
                        background-size: cover;
                        z-index: 10;
                    }
                    .profile-gradient {
                        position: relative;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 30px;
                        background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.8));
                        z-index: 20;
                        margin-top: -30px;
                        pointer-events: none;
                    }
                form {
                    padding-left: 46px;
                    padding-right: 15px;
                    button {
                        margin: 0;
                        margin-left: 50%;
                    }
                    > p {
                        font-size: 12px;
                        color: white;
                    }
                }
                .formular-wrapper {
                    transform: translateX(0);
                }
            }
            h2 {
                color: $white;
                font-size: 46px;
                line-height: 45px;
                margin: 0;
                padding: 0;
                padding-left: 46px;
                letter-spacing: 8px;
            }
            .formular-checkboxes {
                padding-top: 18px;
                padding-bottom: 15px;
                ul {
                    display: inline-block;
                    width: 49%;
                    padding: 0;
                    list-style-type: none;
                    vertical-align: top;
                    > li {
                        color: $white;
                        font-family: $font-regular;
                        font-size: 17px;
                        margin-bottom: 15px;
                        position: relative;
                        cursor: pointer;
                        width: 87%;
                        padding-left: 20px;
                        &:before {
                            display: inline-block;
                            content: "";
                            width: 20px;
                            height: 20px;
                            background: $white;
                            border: 2px solid $lightblue;
                            position: absolute;
                            left: -15px;
                        }
                        &:after {
                            content: "";
                            width: 12px;
                            height: 12px;
                            background: $lightblue;
                            position: absolute;
                            left: -9px;
                            top: 6px;
                            opacity: 0;
                            transition: opacity $default-transition-time;
                        }
                        &.checked {
                            &:after {
                                opacity: 1;
                                transition: opacity $default-transition-time;
                            }
                        }
                    }
                }
            }
            img {
                position: relative;
                float: right;
                width: 96%;
                height: 100%;
            } 
            .download-icon {
                position: relative;
                height: 53px;
                right: 261px;
                top: 15px;
                transform: translateY(-50%);
                pointer-events: none;
                margin-top: -53px;
                z-index: 30;
            }
            .profile-img {
                height: 192px;
                background-position: 30%;
                background-repeat: no-repeat;
                background-size: cover;
                transition: opacity $default-transition-time;
                &:before {
                    display: block;
                    content: "";
                    position: relative;
                    background: rgba(0, 0, 0, 0.5);
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    pointer-events: none;
                    transition: opacity $default-transition-time;
                }
                &:hover {
                    // cursor: pointer;
                    &:before {
                        opacity: 1;
                        transition: opacity $default-transition-time;
                    }
                    > .profile-details {
                        opacity: 1;
                        transition: opacity $default-transition-time;
                    }
                }
                .profile-details {
                    opacity: 0;
                    user-select: none;
                    position: absolute;
                    bottom: 15px;
                    left: 15px;
                    line-height: 24px;
                    > h3 {
                        font-size: 25px;
                        padding: 0;
                        margin: 0;
                    }
                    > p {
                        font-size: 19px;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
            .profile-details-mobile {
                display: none;
                > h3 {
                    text-transform: uppercase;
                    font-size: 25px;
                    padding: 0;
                    margin: 0;
                }
                > p {
                    font-size: 19px;
                    padding: 0;
                    margin: 0;
                }
            }
            .formular-wrapper {
                font-size: 0;
                .formular-content-left {
                    display: inline-block;
                    font-size: 0;
                    width: calc(70% - 46px);
                    padding-left: 46px;
                    > h2 {
                        font-size: 30px;
                        font-family: $font-light;
                        line-height: 45px;
                        margin: 0;
                        margin-top: 18px;
                    }
                }
                .formular-content-right {
                    display: inline-block;
                    font-size: 0;
                    width: 30%;
                    position: relative;
                }
                .formular-checkboxes {
                    padding-left: 46px;
                    width: calc(100% - 46px);
                }
                
            }
        }
    }
}

form {
    margin: 14px 0;
    padding-right: 45px;
    > ::placeholder {
        color: $lightblue;
    }
    > textarea {
        width: calc(100% - 32px);
        height: 59px;
        padding: 16px;
        border: none;
        background: $white;
        resize: none;
        outline: none;
        font-family: $font-medium;
        font-size: 16px;
        &.missing {
            &::placeholder {
                color: red;
            }
            color: red;
        }
    }
    > input {
        &.missing {
            &::placeholder {
                color: red;
            }
            color: red;
        }
    }
}




// MEDIA QUERY TABLET
// from 769px to 1366
// MEDIA QUERY TABLET
@media screen and (max-width: 1366px) { 
    body {
        main {
            .formular {
                h2 {
                    padding-bottom: 10px;
                }
                .form-text {
                    display: block;
                    font-size: 19px;
                    line-height: 31px;
                    font-family: $font-regular;
                    padding-left: 46px;
                }
                .download-icon {
                    right: 0;
                    left: -261px;
                }
                &#form_download {
                    .profile-img {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
        footer {
            .footer-row-container {
                padding-left: 100px !important;
                width: calc(100% - 100px) !important;
                margin: 0 !important;
            }
        }
    }
}

// MEDIA QUERY MOBILE
// From MIN to 768px
// MEDIA QUERY MOBILE
@media screen and (max-width: 768px) {
    body {
        main {
            .formular {
                width: 100%;   
                padding: 0;
                margin: 0;
                h2 {
                    padding: 0 20px;
                    font-size: 20px;
                    line-height: 25px;
                }
                .form-headline {
                    margin-bottom: 11px;
                    letter-spacing: 0.01em;
                }
                .form-text {
                    padding-left: 20px;
                    font-size: 17px;
                }
                &#form_download {
                    max-width: 100%;
                    .profile-gradient {
                        height: 35px !important;
                        margin-top: -35px !important;
                    }
                    > .formular-wrapper {
                        display: flex;
                        flex-direction: column;
                        transform: translateX(0);
                        .formular-content-left {
                            width: calc(100% - 46px);
                        }
                        > .formular-content-right {
                            width: 100%;
                            &.img {
                                order: -1;
                                > img {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    form {
                        display: inline-block;
                        margin: 30px 0;
                        padding: 0 25px;
                        width: calc(100% - 50px);
                        > .inp-size-half {
                            width: 100%;
                            float: left;
                            margin-left: 0;
                        }
                        > .inp-size-full {
                            width: 100%;
                        }
                        > textarea {
                            width: 100%;
                        }
                    }
                }
                &#form_beratung {
                    h2 {
                        font-size: 20px !important;
                        line-height: 25px !important;
                    }
                    > .formular-wrapper {
                        display: grid;
                        flex-direction: column;
                        transform: translateX(0);
                        &.bg-grey_dark {
                            background: transparent;
                        }
                        .img {
                            display: grid;

                            order: -1;
                            .profile-img {
                                width: 100%;
                                height: 193px !important;
                                max-width: 10000px;
                                margin: 0;
                                background-position: 50%;
                            }
                        }
                        > .formular-content-left {
                            padding: 0;
                            background: #5D5D5D !important;
                            width: 100%;
                            > h2 {
                                width: 100%;
                            }
                            &:first-child {
                                background: transparent;
                            }
                            > form {
                                display: inline-block;
                                margin: 30px 0;
                                padding: 0 25px;
                                width: calc(100% - 50px);
                                > .inp-size-half {
                                    width: 100%;
                                    float: left;
                                    margin-left: 0;
                                }
                                > .inp-size-full {
                                    width: 100%;
                                }
                                > textarea {
                                    width: calc(100% - 32px);
                                }
                            }
                        }
                        > .formular-content-right {
                            width: 100%;
                            &.img {
                                > img {
                                    width: 100%;
                                }
                            }
                            > .profile-img {
                                &:before {
                                    opacity: 0;
                                }
                                > .profile-details {
                                    display: none;
                                }
                            }
                            > .profile-details-mobile {
                                display: block;
                                padding: 25px;
                                > h3 {
                                    font-size: 20px;
                                    text-transform: uppercase;
                                }
                                > p {
                                    font-size: 19px;
                                }

                            }
                            
                        }
                    }
                }
                .formular-checkboxes {
                    padding-bottom: 0;
                    padding-top: 18px;
                    padding-left: 40px !important;
                    width: calc(100% - 40px) !important;
                    > ul {
                        width: 100% !important;
                        > li {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
        footer {
            .footer-pre-bottom {
                p {
                    display: block !important;
                    &:last-child {
                        display: none !important;
                    }
                }
            }
        }
    }
}