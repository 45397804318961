// variables mathias
$dark-main: #5c1a25;
$main-color: #8b2939;
$second-color: #ffc400;

// variables dome
// colors
$white : #ffffff;
$lightblue : #49CBD3;
$black : #000000;
$darkgrey : #909090;
$blue : #00ABC8;
$text-dark : #595B60;

// fonts
$font-bold : BreuerText-Bold, Helvetica, Arial, sans-serif;
$font-regular : BreuerText-Regular, Helvetica, Arial, sans-serif;
$font-medium : BreuerText-Medium, Helvetica, Arial, sans-serif;
$font-light : BreuerText-Light, Helvetica, Arial, sans-serif;

// letter spacing

$av_180 : 0.18em;
$av_160 : 0.16em;
$av_20 : 0.02em;
$av_80 : 0.08em;

// import
@import "modules/font";

// other

$header-height : 49px;
$default-transition-time : 200ms;
$text-color-hover : #49CBD3;
$footer-bottom-height : 45px;

body {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    header {
        background: white;
        position: fixed;
        z-index: 2100;
        top: 0;
        left: 0;
        height: $header-height;
        width: 100%;
        #mobile-bar-activator {
            display: none;
        }
        > a > #header-logo {
            display: block;
            height: 32px;
            width: 32px;
            position: absolute;
            left: 70px;
            top: 50%;
            transform: translateY(-50%);
        }
        > nav {
            float: right;
            width: auto;
            height: $header-height;
            > .header-menu {
                padding: 0;
                margin: 0;
                display: inline-block;
                font-family: $font-medium;
                font-weight: normal;
                height: $header-height;
                list-style-type: none;
                > .header-menuPoint {
                    line-height: $header-height;
                    user-select: none;
                    font-size: 19px;
                    cursor: pointer;
                    display: inline-block;
                    color: $darkgrey;
                    transition: color $default-transition-time ease-out;
                    padding: 0 60px;
                    &:hover {
                        color: $blue;
                        transition: color $default-transition-time ease-out;
                        > .header-dropdownMenu {
                            display: block;
                        }
                    }
                    > span > a {
                        color: $darkgrey;
                        text-decoration: none;
                        &:hover {
                            color: #00ABC8;
                        }
                    }
                    > .header-dropdownMenu {
                        display: none;
                        position: absolute;
                        transform: translateX(-20px);
                        background: $white;
                        padding: 0 20px;
                        > ul {
                            padding: 0;
                            margin: 0;
                            > li {
                                list-style-type: none;
                                line-height: 40px;
                                > a {
                                    text-decoration: none;;
                                    font-size: 18px;
                                    color: $darkgrey;
                                    &:hover {
                                        color: $blue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            > .header-iconContainer {
                display:inline-block;
                height: $header-height;
                > .header-icon {
                    position: relative;
                    top: calc(50% - 4px);
                    transform: translateY(-50%);
                    height: 25px;
                    padding-right: 20px;
                    &:last-child {
                        padding-right: 107px;
                    }
                }
            }
        }
    }  
    #mobile-sidebar-overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.3);
        z-index: 1900;
    }
    #mobile-sidebar {
        display: none;
        position: fixed;
        z-index: 2000;
        top: 52px;
        right: 0;
        background: white;
        padding: 0 20px;
        transform: translateX(100%);
        transition: transform 300ms;
        .block {
            ul {
                font-family: $font-regular;
                font-weight: normal;
                padding: 0;
                list-style-type: none;
                > h3 {
                    text-transform: uppercase;
                    font-size: 18px;
                    letter-spacing: $av_80;
                    color: $blue;
                    padding: 10px 0;
                    margin: 0;
                    > a {
                        text-transform: uppercase;
                        text-decoration: none;
                        color: $blue;
                    }
                }
                > li > a {
                    display: block;
                    text-decoration: none;
                    font-size: 17px;
                    line-height: 28px;
                    color: $blue;
                    &:hover {
                        color: $darkgrey;
                    }
                }
                > li {
                    &.current {
                        > a {
                            color: $darkgrey;
                            &:hover {
                                color: $blue;
                            }
                        }
                    }
                }
            }
        }
    }  
}
@media screen and (max-width: 769px) {
    body {
        header {
            #mobile-bar-activator-hitbox {
                display: block;
                position: fixed;
                right: 20px;
                top: 21px;
                width: 20px;
                height: 20px;
                cursor: pointer;
                #mobile-bar-activator {
                    display: block;
                    position: relative;
                    width: 20px;
                    height: 2px;
                    top: 0;
                    left: 0;
                    background: $blue;
                    user-select: none;
                    pointer-events: none;
                    &::before {
                        content: "";
                        display: block;
                        position: relative;
                        width: 20px;
                        height: 2px;
                        background: $blue;
                        top: 14px;
                        left: 0;
                    }
                    &::after {
                        content: "";
                        display: block;
                        position: relative;
                        width: 20px;
                        height: 2px;
                        background: $blue;
                        top: 5px;
                    }
                }
            }
        }
        #mobile-sidebar-overlay {
            display: none;
            &.active {
                display: block;
            }
        }
        #mobile-sidebar {
            display: block;
            &.active {
                transform: translateX(0);
            }
        }
    }
}