.contact-container {
    font-family: $font-regular;
    > .contact-line {
        font-size: 0;
        > .img-container {
            margin: 0;
            padding: 0;
            display: inline-block;
            font-size: 0;
            width: 210px;
            height: 210px;
            overflow: hidden;
            > img {
                margin: 0;
                padding: 0;
                max-width: 100%;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        > .detail-container {
            margin: 0;
            padding: 0;
            display: inline-block;
            font-size: 0;
            width: calc(90% - 210px);
            > p {
                &.zitat {
                    margin: 0;
                    padding: 23px;
                    font-size: 30px;
                    letter-spacing: 5px;
                    line-height: 35px;   
                }
            }
        }
        > .row-big {
            padding: 10px;
            width: calc(100% - 230px);
            display: inline-block;
            font-size: 0;
            > h3 {
                font-size: 25px;
                font-family: $font-bold;
                font-weight: bold;
                line-height: 24px;
                margin: 0;
                padding: 0;
            }
            > p {
                margin: 0;
                padding: 0;
                padding-top: 5px;
                font-size: 19px;
                line-height: 27px;
                max-width: 210px;
                &.zitat {
                    margin: 0;
                    padding: 23px;
                    font-size: 30px;
                    letter-spacing: 5px;
                    line-height: 35px;
                    max-width: 100%;
                }
            }
            > a {
                font-size: 19px;
                line-height: 24px;
                text-decoration: none;
                &:hover {
                    color: white;
                }
            }
        }
        > .row-small {
            width: 210px;
            display: inline-block;
            font-size: 0;
            > .img-container {
                width: 100%;
                height: 210px;
                overflow: hidden;
                > img {
                    max-height: 100%;
                    height: auto;
                    position: relative;
                    left: 50%;
                    transform: translateX(-45%);
                }
            }
            > h3 {
                font-size: 25px;
                line-height: 24px;
                font-family: $font-bold;
                font-weight: bold;
                margin: 0;
                padding: 0;
                padding-top: 15px;
                text-transform: uppercase;
            }
            > p {
                margin: 0;
                padding: 0;
                font-size: 19px;
                line-height: 27px;
                &.zitat {
                    margin: 0;
                    padding: 23px;
                    font-size: 30px;
                    letter-spacing: 5px;
                    line-height: 35px;   
                }
            }
            > a {
                font-size: 19px;
                line-height: 24px;
                text-decoration: none;
                display: block;
                padding-bottom: 20px;
                &:hover {
                    color: white;
                }
            }
        }
    }
}
.contact-persons {
    padding: 0;
    margin: 0 auto;
    max-width: 870px;
    margin-bottom: 23px;
    > .contact-line {
        font-size: 0;
        margin-top: 25px;
        > .single-person-container {
            display: inline-block;
            vertical-align: top;
            width: 210px;
            margin: 0 auto;
            > .img-container {
                width: 210px;
                height: 210px;
                overflow: hidden;
                > img {
                    max-width: 100%;
                }
            }
            > h3 {
                font-size: 25px;
                line-height: 24px;
                margin: 0;
                padding: 0;
                padding-top: 15px;
                text-transform: uppercase;
                font-family: $font-bold;
            }
            > p {
                margin: 0;
                padding: 0;
                font-size: 19px;
                line-height: 27px;
                font-family: $font-regular;
                &.zitat {
                    margin: 0;
                    padding: 23px;
                    font-size: 30px;
                    letter-spacing: 5px;
                    line-height: 35px;   
                }
            }
            > a {
                font-size: 19px;
                line-height: 24px;
                text-decoration: none;
                display: block;
                padding-bottom: 20px;
                font-family: $font-regular;
            }
            &.first {
                margin: 0;
                margin-right: calc(33% - 210px);
            }
            &.middle {
                margin: 0 calc((33% - 210px) / 2);
            }
            &.last {
                margin: 0;
                margin-left: calc(33% - 210px);
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .contact-persons {
        display: none;
    }
}