@font-face {
    font-family: "BreuerText-Bold";
    src:url('fonts/BreuerText/breuertext-bold-webfont.woff') format("opentype");
}
@font-face {
    font-family: "BreuerText-Light";
    src:url('fonts/BreuerText/breuertext-light-webfont.woff') format("opentype");
}
@font-face {
    font-family: "BreuerText-Medium";
    src:url('fonts/BreuerText/BreuerText-Medium.otf') format("opentype");
}
@font-face {
    font-family: "BreuerText-Regular";
    src:url('fonts/BreuerText/breuertext-regular-webfont.woff') format("opentype");
}